import React from "react"
import styled from "styled-components"
import { ViewportProvider } from "../hooks/useViewport"
import { GlobalStyle } from "../theme/globalStyle"
import Footer from "./footer"
import Navigation from "./navigation/navigation"

const Container = styled.div``

const Layout = ({ children, stacked }) => {
  return (
    <ViewportProvider>
      <GlobalStyle />
      <Container>
        <Navigation stacked={stacked} />
        {children}
        <Footer />
      </Container>
    </ViewportProvider>
  )
}

export default Layout
