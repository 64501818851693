import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { slide as HamburgerMenu } from "react-burger-menu"
import NavIcon from "../../assets/NavIcon.svg"

const NavigationContainer = styled.div`
  // width: 100%;
  // height: 80px;

  svg {
    max-width: 54px;
    max-height: 54px;
    margin-left: 16px;
    margin-top: 20px;
    position: fixed;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: initial;
    right: 36px;
    top: 36px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  a {
    text-decoration: none;
    color: white;
    font-family: "Lato";
    font-weight: 300;
    font-size: 18px;
    margin-top: 50px;
  }
`

const MobileNavigation = () => (
  <NavigationContainer>
    {/* <NavIcon /> */}
    <HamburgerMenu>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/bio/about">Biography</Link>
      <Link to="/bio/broadcaster">Broadcaster</Link>
      <Link to="/bio/businessman">Businessman</Link>
      <Link to="/bio/statesman">Statesman</Link>
      <Link to="/bio/christian">Christian Activist</Link>
      <Link to="/bio/educator">Educator</Link>
      <Link to="/collection">The Collection</Link>
      <Link to="/ellen-armstrong">Ellen Armstrong</Link>
      <Link to="/award">William L. Armstrong Award</Link>
    </HamburgerMenu>
  </NavigationContainer>
)

export default MobileNavigation
