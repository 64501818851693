import React from 'react';
import styled from 'styled-components';


const Button = styled.div`
  border: 1px solid ${props => props.color};
  color: ${props => props.color};
  font-family: 'Lato';
  font-size: ${props => props.fontSize};
  text-align: center;
  opacity: 0.6;
  padding: 10px;
  cursor: ${props => props.cursor}
`;

const StandardButton = ({ color, styles = {}, text, onClick, cursor = 'default', fontSize = '18px' }) => {
  return (
    <div className="standard-button" style={{ width: '15.3vw', height: '5.5vh', margin: 'auto', paddingTop: '15px', ...styles }}>
      <Button onClick={onClick} color={color} fontSize={fontSize} cursor={cursor}>{text}</Button>
    </div>
  )
}

export default StandardButton